<template>
  <section>
    <v-container>
      <v-row
        class="pt-15"
        justify="center"
        align="center"
      >
        <v-col
          cols="11"
          md="8"
          lg="8"
          xl="5"
        >
          <kn-form-title
            :title="title"
            :routerName="routerName"
          />
          <kn-text-field
            class="mt-4"
            v-model="entity.numero_orden"
            label="Número de orden"
            :rules="[]"
            disabled
          />
          <kn-text-field
            class="mt-4"
            v-model="entity.cliente"
            label="Cliente"
            :rules="[]"
            disabled
          />
          <kn-text-field
            class="mt-4"
            v-model="entity.total_orden"
            label="Total de la orden"
            :rules="[]"
            disabled
          />
          <kn-form-subtitle title="Solicitud de cancelación" />
          <kn-select
            v-model="cancellation.id_motivo_cancelacion"
            label="Motivo de cancelación"
            :rules="[]"
            :items="cancellationReasons"
            item-text="dato"
            item-value="id"
          />
          <kn-select
            v-model="cancellation.id_estatus_cancelacion"
            label="Estatus de cancelación"
            :rules="[]"
            :items="cancellationStatus"
            item-text="dato"
            item-value="id"
          />
          <kn-text-area
            v-model="cancellation.comentarios"
            label="Comentarios"
            :rules="[]"
          />
          <kn-select
            v-model="cancellation.id_aprobador"
            label="Quien aprueba?"
            :rules="[]"
            :items="adminEmployees"
            item-text="datos_fiscales.nombre_o_razon_social"
            item-value="id"
          />
          <kn-check-box
            v-model="cancellation.solicitado_por_cliente"
            label="Acepto continuar con la cancelación"
            :rules="[]"
          />
          <section v-if="cancellation.solicitado_por_cliente">
            <kn-form-subtitle title="Egreso por cancelación" />
            <kn-select
              v-model="expense.id_categoria"
              label="Categoría"
              :rules="[]"
              :items="expenseCategories"
              item-text="dato"
              item-value="id"
            />
            <kn-select
              v-model="expense.id_sub_categoria"
              label="Sub categoría"
              :rules="[]"
              :items="expenseSubcategories"
              item-text="dato"
              item-value="id"
            />
            <kn-select
              v-model="expense.id_forma_de_pago"
              label="Forma de pago"
              :rules="[]"
              :items="paymentMethods"
              item-text="dato"
              item-value="id"
            />
            <kn-select
              v-model="expense.id_cuenta_origen"
              label="Cuenta de origen"
              :rules="[]"
              :items="bankAccounts"
              item-text="nombre_cuenta"
              item-value="id"
            />
          </section>
          <kn-form-action-buttons
            acceptBtnLabel="Aceptar"
            :disableAccept="!enableBtnAccept"
            @accept="save"
            @cancel="cancel"
          />
        </v-col>
      </v-row>
    </v-container>
    <kn-back-to-top-button />
    <kn-local-alert
      v-model="showAlert"
      :alertText="alertText" 
      :alertType="alertType"
      :alertColor="alertColor"
      :loadingAlert="loading"
      @action1="actionAlertBtn1"
      @action2="continueAdding"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import KnSelect from '@/components/inputs/KnSelect.vue'
import KnFormSubtitle from '@/components/KnFormSubtitle.vue'
import KnLocalAlert from '@/components/KnLocalAlert.vue'
// import KnTabs from '@/components/KnTabs.vue'
import { fileUtilsMixin } from '@/mixins/fileUtilsMixin'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import { apiMixin } from '@/mixins/apiMixin'
import { addressUtilsMixin } from '@/mixins/addressUtilsMixin'
import { arrayUtilsMixin } from '@/mixins/arrayUtilsMixin'
import KnFormActionButtons from '@/components/KnFormActionButtons.vue'
import KnTextArea from '../../../components/inputs/KnTextArea.vue'
import KnFormTitle from '../../../components/KnFormTitle.vue'
import KnBackToTopButton from '../../../components/forms/KnBackToTopButton.vue'
import KnCheckBox from '../../../components/inputs/KnCheckBox.vue'
import KnTextField from '../../../components/inputs/KnTextField.vue'
export default {
  components: {
    KnFormSubtitle,
    KnSelect,
    KnLocalAlert,
    KnFormActionButtons,
    KnTextArea,
    KnFormTitle,
    KnBackToTopButton,
    KnCheckBox,
    KnTextField
  },
  mixins: [
    fileUtilsMixin,
    validationFormMixin,
    apiMixin,
    addressUtilsMixin,
    arrayUtilsMixin
  ],
  props: {
    entity: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      tabs: [
        { name: 'Solicitud de cancelación', value: 100 },       
      ],
      valueDeterminate: 100,
      routerName: 'Ordenes',
      orderId: null,
      cancellation: {
        id_orden: null,
        id_motivo_cancelacion: null,
        id_estatus_cancelacion: null,
        comentarios: '',
        id_aprobador: null, // Empleado
        solicitado_por_cliente: false,
        id_autor: null, // Usuario logueado
        id_institucion_educativa: null
      },
      expense: {
        id_proveedor: null,
        id_empleado: null,
        id_categoria: null,
        id_sub_categoria: null,
        sub_total: 0.00,
        total_impuestos: 0.00,
        costo_envio: 0.00,
        total_descuento: 0.00,
        total_egreso: 0.00,
        descuento_aplicado: false,
        id_forma_de_pago: null,
        id_moneda: null,
        id_cuenta_origen: null,
        id_estatus: null,
        id_centro_de_costos: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      order: {
        numero_orden: null,
        id_estatus_orden: null,
        id_alumno: null,
        id_datos_facturacion: null,
        id_direccion: null,
        peso_volumetrico: null,
        sub_total: null,
        total_impuestos: null,
        costo_envio: 0.00,
        total_descuento: 0.00,
        total_orden: null,
        id_moneda: null,
        descuento_aplicado: false,
        fecha_elaboracion: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      orderDetails: [],
      cancellationStatusId: null, // Debera ser Aprobada
      /** Arrays para cancelacion */
      cancellationReasons: [],
      cancellationStatus: [],
      adminEmployees: [],
      /** ****** */
      /** Arrays para egreso */
      expenseCategories: [],
      expenseSubcategories: [],
      paymentMethods: [],
      coins: [],
      expenseStatus: [],
      bankAccounts: [],
      /** ****** */
      /** Arrays para inventarios */
      movementTypes: [],
      movementReasons: [],
      /** ****** */
      products: [],
      orderStatus: [],
      students: [],
      shippingTypes: [],
      measurementUnits: [],
      items: [],
      headers: [
        { text: 'Producto', value: 'producto.nombre_producto', class: 'purple--text' },
        { text: 'Peso volumétrico', value: 'producto.peso_volumetrico', class: 'purple--text' },
        { text: 'Cantidad', value: 'unidades', class: 'purple--text'},
        { text: 'Unidad de medida', value: 'unidad_medida.dato', class: 'purple--text'},
        { text: 'Precio unitario', value: 'precio_unitario', class: 'purple--text'},
        { text: 'Sub total', value: 'sub_total', class: 'purple--text'},
        { text: 'Impuestos', value: 'total_impuesto', class: 'purple--text'},
        { text: 'Total', value: 'total', class: 'purple--text'},
      ],

       /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /************************ */
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    title() {
      return this.entity === null ? 'Crear cancelación' : 'Detalle cancelación'
    },
    isEditMode() {
      return this.entity !== null
    },
    successAlertText() {
      return this.isEditMode ? 'Cancelación creada con éxito!' : 'Cancelación creada con éxito!'
    },
    successAlertType() {
      return this.isEditMode ? 'info' : 'success'
    },
    enableBtnAccept() {
      return this.order.id_estatus_orden !== this.entity.estatus_orden.id
    },
  },
  async created() {
    this.loading = true
    this.alertText = 'Por favor, espere. Cargando...'
    this.showAlert = true
    this.alertType = 'info'

    if (this.orderStatus.length === 0) {
      const status = await this.fetchResultsAll('ordenes', 'mv-estatus-orden-all')
      this.orderStatus = status.filter(s => s.dato
                                              .toLowerCase()
                                              .includes('cancelada'))
    }
    if (this.cancellationReasons.length === 0) {
      this.cancellationReasons = await this.fetchResultsAll('ordenes', 'motivo-cancelacion-all')
    }
    if (this.cancellationStatus.length === 0) {
      this.cancellationStatus = await this.fetchResultsAll('ordenes', 'estatus-cancelacion-all')
    }
    if (this.adminEmployees.length === 0) {
      const employees = await this.fetchResultsByEI('personas', 'empleado', this.institutionId)
      if (employees.length) {
        this.adminEmployees = employees.filter(employee => employee.tipo_empleado.dato
                                                            .toLowerCase()
                                                            .includes('administrativo'))
      }
    }
    if (this.expenseCategories.length === 0) {
      const categories = await this.fetchResultsByEI('administracion', 'categoria-egreso', this.institutionId)
      this.expenseCategories = categories.filter(category => category.dato
                                                              .toLowerCase()
                                                              .includes('cancelacion'))
    }
    if (this.expenseSubcategories.length === 0) {
      const subcategories = await this.fetchResultsByEI('administracion', 'subcategoria-egreso', this.institutionId)
      this.expenseSubcategories = subcategories.filter(subcategory => subcategory.dato
                                                                      .toLowerCase()
                                                                      .includes('devolucion'))
    }
    if (this.paymentMethods.length === 0) {
      const allPaymentMethods = await this.fetchResultsByEI('administracion', 'forma-de-pago', this.institutionId)
      this.paymentMethods = this.activeItems(allPaymentMethods)
    }
    if (this.coins.length === 0) {
      this.coins = await this.fetchResultsByEI('administracion', 'moneda', this.institutionId)
    }
    if (this.expenseStatus.length === 0) {
      this.expenseStatus = await this.fetchResultsByEI('administracion', 'estatus-egreso', this.institutionId)
    }
    if (this.bankAccounts.length === 0) {
      const allBankAccounts = await this.fetchResultsByEI('administracion', 'cuanta', this.institutionId)
      this.bankAccounts = this.activeItems(allBankAccounts)
    }

    const cancellationStatusFinded = this.cancellationStatus.find(cStatus => cStatus.dato.toLowerCase().includes('aprobada'))
    this.cancellationStatusId = cancellationStatusFinded ? cancellationStatusFinded.id : null

    // this.orderStatus = await this.fetchResultsAll('ordenes', 'mv-estatus-orden-all')
    // this.students = await this.fetchResultsByEI('personas', 'alumno', this.institutionId)
    // this.shippingTypes = await this.fetchResultsAll('ordenes', 'tipo-envio-all')
    // this.products = await this.fetchResultsByEI('productos', 'producto', this.institutionId)
    // this.measurementUnits = await this.fetchResultsByEI('productos', 'uom', this.institutionId)
    // this.coins = await this.fetchResultsByEI('administracion', 'moneda', this.institutionId)
    
    // if (this.isEditMode) {
      // console.log('Se deben llenar los campos');
      this.fillData()
    // } else {
    //   this.order.id_institucion_educativa = this.institutionId
    //   this.order.id_autor = this.userData.id
    // }
    this.showAlert = false
  },
  methods: {
    setTabValue(val) {
      this.valueDeterminate = val
    },
    async createObjects() {
      try {
        this.loading = true
        this.alertText = 'Cargando...'
        this.showAlert = true

        this.alertText = 'Creando solicitud de cancelación'
        const orderStatus = this.findOrderStatusByCancellationStatus(this.cancellation.id_estatus_cancelacion)
        console.log('Estatus orden encontrada', orderStatus);

        await this.createCancellation(this.cancellation)
        
        if (this.errors.length === 0) {
          await this.updateOrderStatus(this.orderId, orderStatus.id)

          // Si el estatus es Aprobada
          if (this.cancellation.id_estatus_cancelacion === this.cancellationStatusId) {
            this.alertText = 'Creando egreso'
            await this.createExpense(this.expense)

            if (this.errors.length === 0) {
              for (const orderDetail of this.orderDetails) {
                let inventory = null
                const responseInventory = await this.postObj(
                  '/inventarios/inventarios-producto',
                  { id_producto: orderDetail.producto.id }
                )
                const allInventory = await responseInventory['lista_inventarios producto']

                // Revisamos si orden detalle tiene opcion producto
                // esto por si hay varios inventarios relacionados
                // a un producto se pueda distinguir el inventario
                // correcto a actualizar
                if (orderDetail.opcion_producto) {
                  const inventoryFinded = allInventory
                                            .find(i => i.producto.id === orderDetail.producto.id 
                                                        && i.opcion_producto.id === orderDetail.opcion_producto.id)
                  if (inventoryFinded) {
                    inventory = {...inventoryFinded}
                  }
                } else {
                  if (allInventory.length) {
                    inventory = allInventory[0]
                  }
                }

                if (inventory) {
                  this.alertText = 'Actualizando inventario'
                  await this.updateInventory({
                    inventory,
                    quantity: parseInt(orderDetail.unidades)
                  })
                  if (this.errors.length === 0) {
                    this.alertText = 'Creando movimiento de inventario'
                    await this.createInventoryMovement({
                      inventory, 
                      quantity: parseInt(orderDetail.unidades),
                      price: orderDetail.precio_unitario,
                      coinId: orderDetail.orden.moneda
                    })
                  }
                }
              }
            }
          }
        }

        this.loading = false

        if (this.errors.length > 0) {
          this.alertType = 'error'
          this.alertText = this.errors.join(', ')
        } else {
          this.alertType = this.successAlertType
          this.alertColor = 'success'
          this.alertText = this.successAlertText
        }
      } catch (error) {
        console.error('Error al intentar crear los objetos relacionados a solicitud de cancelacion', error);
      }
    },
    async updateObjects() {
      try {
        this.loading = true
        this.alertText = 'Cargando...'
        this.showAlert = true

        await this.updateOrderStatus()

        this.loading = false
        if (this.errors.length > 0) {
          this.alertType = 'error'
          this.alertText = this.errors.join(', ')
        } else {
          this.alertType = this.successAlertType
          this.alertColor = 'success'
          this.alertText = this.successAlertText
        }
      } catch (error) {
        console.error('Error al intentar actualizar inventario o crear movimiento inventario')
      }
    },
    async save() {
      //if (this.isEditMode) {
        // this.updateObjects()
      //} else {
        this.createObjects()
      //}
    },
    cancel() {
      this.returnToTable()
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType  === 'info') {
        this.returnToTable()
      } else {
        this.closeAlert()
      }
    },
    continueAdding() {
      this.clean()
      this.closeAlert()
    },
    returnToTable() {
      this.$router.replace({name: this.routerName})
    },
    closeAlert() {
      this.errors = []
      this.showAlert = false
    },
    findOrderStatusByCancellationStatus(cancellationStatusId) {
      const cancellationStatusFinded = this.cancellationStatus.find(cStatus => cStatus.id === cancellationStatusId)
      let orderStatus = {}
      const statusText = cancellationStatusFinded.dato.toLowerCase()
      if (statusText.includes('creada')) {
        orderStatus = this.orderStatus.find(oStatus => oStatus.dato.toLowerCase().includes('solicitud cancelación'))
      } else if (statusText.includes('aprobada')) {
        orderStatus = this.orderStatus.find(oStatus => oStatus.dato.toLowerCase().includes('cancelada'))
      } else if (statusText.includes('rechazada')) {
        orderStatus = this.orderStatus.find(oStatus => oStatus.dato.toLowerCase().includes('pagada'))
      }
      return orderStatus 
    },
    findProductPrice() {
      const product = this.products.find(p => p.id === this.productId)
      const productPrice = product.precios.find(pr => pr.distribuidor === this.institutionId)
      this.distributionPrice = productPrice.precio_distribuidor
      this.inventory.id_moneda = productPrice.moneda
    },
    async fillData() {
      console.log('Entity: ', this.entity);
      const approvedStatus = this.expenseStatus
                              .find(eStatus => eStatus.dato.toLowerCase().includes('aprobado'))
      this.orderId = this.entity.id

      this.cancellation.id_orden = this.orderId
      this.cancellation.id_autor = this.userData.id
      this.cancellation.id_institucion_educativa = this.institutionId

      this.expense.sub_total = parseFloat(this.entity.sub_total)
      this.expense.total_impuestos = parseFloat(this.entity.total_impuestos)
      this.expense.costo_envio = parseFloat(this.entity.costo_envio)
      this.expense.total_descuento = parseFloat(this.entity.total_descuento)
      this.expense.total_egreso = parseFloat(this.entity.total_orden)
      this.expense.descuento_aplicado = this.entity.descuento_aplicado
      this.expense.id_moneda = this.entity.moneda.id
      this.expense.id_estatus = approvedStatus.id
      this.expense.id_centro_de_costos = 1 // Debe existir un centro de costos
      this.expense.id_autor = this.userData.id
      this.expense.id_institucion_educativa = this.institutionId
      this.expense.comentarios = `Egreso por cancelación. Orden: ${this.entity.numero_orden}`

      this.orderDetails = await this.fetchResultsWithParams(
        'ordenes',
        'detalle-orden-by-orden',
        { id_orden: this.orderId }
      )
    },
    // Metodos involucrados en una cancelacion
    async createCancellation(cancellationObj) {
      try {
        const response = await this.postObj('/ordenes/crear-cancelacion', cancellationObj)
        if (response.error) {
          this.errors.push('Error al crear cancelación')
          console.log('Error al crear cancelacion', response.error);
        } else {
          this.alertText = 'Solicitud de cancelación creado con éxito'
        }
      } catch (error) {
        this.errors.push('Error al intentar crear una cancelación')
        console.error('Error al intentar crear una cancelación', error);
      }
    },
    async updateOrderStatus(orderId, orderStatusId) {
      try {
        const response = await this.postObj('/ordenes/update-estatus-orden', {
          id_orden: orderId,
          id_estatus_orden: orderStatusId
        })
        if (response.error) {
          this.errors.push('Error al actualizar estatus de orden.')
        } else {
          this.alertText = 'Estatus de orden actualizada'
        }
      } catch (error) {
        console.error('Error al intentar actualizar estatus de orden', error);
      }
    },
    async createExpense(expenseObj) {
      try {
        console.log('Objeto expense', expenseObj);
        const response = await this.postObj('/administracion/crear-egreso', expenseObj)
        if (response.error) {
          this.errors.push('No se pudo crear egreso.')
        } else {
          this.alertText = 'Egreso creado con éxito'
        }
        console.log('Respuesta crear egreso', response);
      } catch (error) {
        this.errors.push('Error al intentar crear egreso')
        console.error('Error al intentar crear egreso', error);
      }
    },
    async updateInventory({ inventory, quantity }) {
      try {
        const response = await this.postObj('/inventarios/update-inventario', {
          id: inventory.id,
          id_producto: inventory.producto.id,
          id_opcion_producto: inventory.opcion_producto.id,
          id_almacen: inventory.almacen.id,
          cantidad_disponible: parseInt(inventory.cantidad_disponible) + quantity,
          id_unidad_de_medida: inventory.unidad_medida.id,
          comentarios: inventory.comentarios,
          fecha_ultima_actualizacion: new Date(),
          id_autor: this.userData.id,
          id_institucion_educativa: this.institutionId
        })
        if (response.error) {
          this.errors.push('No se pudo actualizar inventario')
        } else {
          this.alertText = 'Inventario actualizado exitosamente'
        }
      } catch (error) {
        this.errors.push('Error al intentar actualizar inventario')
        console.error('Error al intentar actualizar inventario');
      }
    },
    async createInventoryMovement({inventory, quantity, price, coinId}) {
      try {
        this.movementTypes = await this.fetchResultsAll('inventarios', 'mv-movimiento-inventario-all')
        this.movementReasons = await this.fetchResultsAll('inventarios', 'motivo-movimiento-inventario-all')
        const movementType = this.movementTypes.find(mType => mType.dato.toLowerCase().includes('entrada'))
        const movementReason = this.movementReasons.find(mReason => mReason.dato.toLowerCase().includes('devolución'))

        const initialAmount = parseInt(inventory.cantidad_disponible)
        const amountOfMovement = quantity
        const finalAmount = initialAmount + amountOfMovement
        const unitPrice = parseFloat(price)
        const valueOfMovement = unitPrice * amountOfMovement

        const movementObj = {
          id_producto: inventory.producto.id,
          id_tipo_movimiento: movementType.id, // Tipo de movimiento: Entrada
          id_motivo_movimiento: movementReason.id, // Motivo de movimiento: Devolucion
          id_almacen: inventory.almacen.id,
          inventario_inicial: initialAmount,
          cantidad_movimiento: amountOfMovement,
          inventario_final: finalAmount,
          precio_unitario: unitPrice,
          valor_movimiento: valueOfMovement,
          id_moneda: coinId,
          id_autor: this.userData.id,
          id_institucion_educativa: this.institutionId
        }

        const response = await this.postObj('/inventarios/crear-movimiento-inventario', movementObj)

        if (response.error) {
          this.errors.push('No se pudo crear movimiento de inventario.')
        } else {
          this.alertText = 'Movimiento de inventario creado con exito'
        }
      } catch (error) {
        console.error('Error al intentar crear movimiento de inventario', error)
        this.errors.push('Error al intentar crear movimiento de inventario')
      }
    }
  }
}
</script>
